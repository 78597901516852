import { useContext, createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Create the authentication context
const AuthContext = createContext();

const AuthProvider = ({ children }) => {

    //const [user, setUser] = useState({});
    const navigate = useNavigate();

    // This code is called each time a route in invoked.
    // The code below this (loginAction and logout)
    // is called when a user logs in or out.
    const userFromSession = JSON.parse(sessionStorage.getItem("flyers")) || {};
    const [user, setUser] = useState(userFromSession);

    const loginAction = async (data, setError) => {
        try {
            let updatedUser = {};
            const response = await fetch(process.env.REACT_APP_EXPRESS_SERVER + "/login", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });
            const res = await response.json();

            // A 401 response indicates user entered
            // an invalid email or password.
            if (response.status === 401) {
                setError(res.error);
                return;
            }
            if (res?.token && res?.email) {
                updatedUser = {
                    token: res.token,
                    email: res.email,
                    role: res.role,
                    isDuesCurrent: res.isDuesCurrent,
                };
                setUser(updatedUser);
            }
            if (res.isDuesCurrent) {
                sessionStorage.setItem("flyers", JSON.stringify(updatedUser));
                navigate("/TueThuRides");
            } else {
                navigate("/MyAccount");
            }
        } catch (err) {
            if (err instanceof TypeError && err.message.includes("Failed to fetch")) {
                console.error("Network error: Connection refused", err);
                setError("Unable to connect to the server. Please try again later.");
            } else {
                console.error("An unknown error occurred:", err);
                setError("An unknown login error occurred.");
            }
        }
    };

    const LogoutAction = () => {
        useEffect(() => {
            setUser({});
            sessionStorage.removeItem('flyers');
            navigate("/Login");
        }, []);
    };

    return (
        <AuthContext.Provider value={{ user, loginAction, LogoutAction }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;

// useAuth utilizes useContext to access the authentication
// context including an authentication token and user data
// from within components, allowing them to consume the
// authentication state and related functions stored in
// the context.
export const useAuth = () => {
    return useContext(AuthContext);
};