

/*
It's not know how a user will enter a url.
Example:
    google.com
    www.google.com
    http://google.com
    https://google.com

So strip 'http://', 'https://', 'www.' from
whatever is entered and then build the protocol
up again.
*/
export const makeUrl = (url) => {
  let validUrl = url;
  validUrl = validUrl.replace(/^https?:\/\//, '');
  validUrl = validUrl.replace(/www\./, '');
  return 'https://www.' + validUrl;
}

/*
Function to parse the date string manually
when it's in this format: '15-Mar-23'
The function returns a date object.
*/
export const makeDate = (input) => {
  if (input) {
    const parts = input.split('-');
    const day = parseInt(parts[0], 10);
    const monthAbbreviation = parts[1];
    const year = parseInt(parts[2], 10) + 2000;

    // Map month abbreviation to month index
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const month = monthNames.indexOf(monthAbbreviation);

    // Create a new Date object
    return new Date(year, month, day);
  }
  return null;
};

// Return true if object is empty.
// Otherwise, return true.
export const isEmpty = (obj) => {
  for (var x in obj) { return false; }
  return true;
}

// Return the current date in yyyy/mm/dd format.
// This is the format that Postgres expects.
export const currentDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

// Format the date in MyAccount -> Payment History
export function formatDate(dateString) {
  const [year, month, day] = dateString.split('-');
  return `${month}/${day}/${year}`;
}

// Return true if client is a mobile device else return false
export const isMobile = () => {
  const userAgent = navigator.userAgent;
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
};