export const verifyEmail = async (userId, token, setError) => {
    try {

        const data = { 'userId': userId, 'token': token };
        const response = await fetch(process.env.REACT_APP_EXPRESS_SERVER + "/verifyEmail", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        const res = await response.json();
        if (response.status === 400) {
            setError(res.error);
        }
    } catch (err) {
        if (err instanceof TypeError && err.message.includes("Failed to fetch")) {
            console.error("Network error: Connection refused", err);
            setError("Unable to connect to the server. Please try again later.");
        } else {
            console.error("An error occurred:", err);
            setError("An unknown error occurred while validating your email.");
        }
    }
}