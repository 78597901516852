import React, { useState, useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import PaypalCheckoutButton from "./PaypalCheckoutButton";
import ErrorMsg from '../ErrorMsg'

export default function SelectedTier() {

  const [message, setMessage] = useState("");
  const [tier, setTier] = useState("");
  const [price, setPrice] = useState('0.00');
  const [dues, setDues] = useState('');
  const [variant, setVariant] = useState('secondary');
  const priceDiv = useRef(null);
  const location = useLocation();

  useEffect(() => {
    setTier(location.state[0]);
    setPrice(location.state[1]);
    setDues(location.state[2]);
  }, [location.state]);

  return (
    <div className="selected-tier-outer">
      <div>
        <div style={{ width: '100%', flexDirection: 'column' }} className='selected-tier-inner'>
          {message &&
            <ErrorMsg
              setError={setMessage}
              variant={variant}
              error={message}
            />
          }
        </div>
        <div className="center-text">
          <h2>Your Selected Tier</h2>
        </div>
        <Card className={`selected-tier-inner p-3 ${dues}`}>
          <h3>{tier} Membership</h3>
          <p>
            Ditch the solo spin and join the pack!
            Discover camaraderie, support, and
            shared journeys with fellow cyclists.
            Pedal into adventure and forge lifelong
            friendships – it's more than just riding,
            it's belonging to a community.
          </p>
          <p>
            <strong ref={priceDiv}>
              {price} USD
            </strong>
          </p>
        </Card>
        <div style={{ marginTop: 20 }} className="paypal-button-container">
          <PaypalCheckoutButton
            setMessage={setMessage}
            setVariant={setVariant}
            priceDiv={priceDiv}
          />
        </div>
      </div>
    </div>
  );
}