export const libraryData = () => {
    return [
        { name: '<a href="https://ridewithgps.com/routes/4159996">421/801</a>', sortKey: '421/801', distance: 39, elevation: 1750 },
        { name: '<a href="https://ridewithgps.com/routes/4160002">801/421</a>', sortKey: '801/421', distance: 39, elevation: 1743 },
        { name: '<a href="https://ridewithgps.com/routes/3556690">801/Farmington B</a>', sortKey: '801/Farmington B', distance: 34.5, elevation: 1583 },
        { name: '<a href="https://ridewithgps.com/routes/3556658">801/Farmington C</a>', sortKey: '801/Farmington C', distance: 32, elevation: 1350 },
        { name: '<a href="https://ridewithgps.com/routes/9243666">801/Pineville C</a>', sortKey: '801/Pineville C', distance: 34.3, elevation: 1370 },
        { name: '<a href="https://ridewithgps.com/routes/345436">Almost Rockford</a>', sortKey: 'Almost Rockford', distance: 52.7, elevation: 2026 },
        { name: '<a href="https://ridewithgps.com/routes/32159813">Amish Bakery Out & Back</a>', sortKey: 'Amish Bakery Out & Back', distance: 62.4, elevation: 2563 },
        { name: '<a href="https://ridewithgps.com/trips/7482584">Angell Road</a>', sortKey: 'Angell Road', distance: 52.9, elevation: 2110 },
        { name: '<a href="https://ridewithgps.com/routes/45012353">Bad Haircut Mountain p/b Bob Weir</a>', sortKey: 'Bad Haircut Mountain p/b Bob Weir', distance: 63.4, elevation: 2766 },
        { name: '<a href="https://ridewithgps.com/routes/822648">Bad Haircut Mountain w/ Bakery</a>', sortKey: 'Bad Haircut Mountain w/ Bakery', distance: 73.8, elevation: 2909 },
        { name: '<a href="https://ridewithgps.com/routes/4785519">Bad Whoopie Haircut</a>', sortKey: 'Bad Whoopie Haircut', distance: 67.9, elevation: 2842 },
        { name: '<a href="https://ridewithgps.com/trips/8117576">Bakery Long</a>', sortKey: 'Bakery Long', distance: 75.3, elevation: 2819 },
        { name: '<a href="https://ridewithgps.com/routes/41994555">Baltimore Church Extension B (reverse)</a>', sortKey: 'Baltimore Church Extension B (reverse)', distance: 31.4, elevation: 1595 },
        { name: '<a href="https://ridewithgps.com/routes/4203590">Baltimore Church Extension C (reverse)</a>', sortKey: 'Baltimore Church Extension C (reverse)', distance: 31, elevation: 1441 },
        { name: '<a href="https://ridewithgps.com/routes/3789780">Baltimore Church Extension C</a>', sortKey: 'Baltimore Church Extension C', distance: 31, elevation: 1443 },
        { name: '<a href="https://ridewithgps.com/routes/41994518">Baltimore Church Extension-B</a>', sortKey: 'Baltimore Church Extension-B', distance: 31.4, elevation: 1590 },
        { name: '<a href="https://ridewithgps.com/routes/43783023">Bereft No More</a>', sortKey: 'Bereft No More', distance: 62.1, elevation: 2690 },
        { name: '<a href="https://ridewithgps.com/routes/43783001">Bereft at the Moment</a>', sortKey: 'Bereft at the Moment', distance: 62.1, elevation: 2690 },
        { name: '<a href="https://ridewithgps.com/routes/1781788">Beroth Logan Creek</a>', sortKey: 'Beroth Logan Creek', distance: 43.5, elevation: 2287 },
        { name: '<a href="https://ridewithgps.com/routes/1781789">Beroth Williams</a>', sortKey: 'Beroth Williams', distance: 44.5, elevation: 2431 },
        { name: '<a href="https://ridewithgps.com/routes/3840148">Bethel Church B</a>', sortKey: 'Bethel Church B', distance: 30.2, elevation: 1406 },
        { name: '<a href="https://ridewithgps.com/routes/3840146">Bethel Church C</a>', sortKey: 'Bethel Church C', distance: 27.6, elevation: 1165 },
        { name: '<a href="https://ridewithgps.com/routes/41022808">Big Chicken Reverse</a>', sortKey: 'Big Chicken Reverse', distance: 66.9, elevation: 3384 },
        { name: '<a href="https://ridewithgps.com/routes/6776935">Bloomtown (reverse)</a>', sortKey: 'Bloomtown (reverse)', distance: 19.2, elevation: 821 },
        { name: '<a href="https://ridewithgps.com/routes/6997161">Bloomtown</a>', sortKey: 'Bloomtown', distance: 19.2, elevation: 820 },
        { name: '<a href="https://ridewithgps.com/routes/363650">Bob Marler</a>', sortKey: 'Bob Marler', distance: 61.6, elevation: 2080 },
        { name: '<a href="https://ridewithgps.com/trips/9002765">Boone\'s Cave</a > ', sortKey: 'Boone\'s Cave', distance: 47.5, elevation: 2538 },
        { name: '<a href="https://ridewithgps.com/routes/28148244">Boonville Express</a>', sortKey: 'Boonville Express', distance: 50.4, elevation: 2110 },
        { name: '<a href="https://ridewithgps.com/routes/368076">Boonville</a>', sortKey: 'Boonville', distance: 56.5, elevation: 1847 },
        { name: '<a href="https://ridewithgps.com/routes/4160012">Brandon Fish</a>', sortKey: 'Brandon Fish', distance: 43, elevation: 1483 },
        { name: '<a href="https://ridewithgps.com/routes/4160020">Brandon Hills</a>', sortKey: 'Brandon Hills', distance: 45.9, elevation: 1875 },
        { name: '<a href="https://ridewithgps.com/routes/3789798">Bridge Out C</a>', sortKey: 'Bridge Out C', distance: 37.5, elevation: 1678 },
        { name: '<a href="https://ridewithgps.com/routes/3789827">Brokeback Mountain C</a>', sortKey: 'Brokeback Mountain C', distance: 36.4, elevation: 1701 },
        { name: '<a href="https://ridewithgps.com/routes/3791339">Brushy Fondo Option</a>', sortKey: 'Brushy Fondo Option', distance: 81.7, elevation: 5572 },
        { name: '<a href="https://ridewithgps.com/routes/4159460">Bunny Rabbit B</a>', sortKey: 'Bunny Rabbit B', distance: 33.5, elevation: 1645 },
        { name: '<a href="https://ridewithgps.com/routes/3789841">Bunny Rabbit C</a>', sortKey: 'Bunny Rabbit C', distance: 31, elevation: 1406 },
        { name: '<a href="https://ridewithgps.com/routes/4159125">Bunny Rabbit II C</a>', sortKey: 'Bunny Rabbit II C', distance: 35.3, elevation: 1557 },
        { name: '<a href="https://ridewithgps.com/routes/370251">Bunny Rabbit</a>', sortKey: 'Bunny Rabbit', distance: 35.6, elevation: 1270 },
        { name: '<a href="https://ridewithgps.com/routes/6542931">Carmelizer</a>', sortKey: 'Carmelizer', distance: 41.5, elevation: 1859 },
        { name: '<a href="https://ridewithgps.com/routes/35999503">Carroll\'s Man Card</a > ', sortKey: 'Carroll\'s Man Card', distance: 53.2, elevation: 2034 },
        { name: '<a href="https://ridewithgps.com/routes/373344">Center Marler</a>', sortKey: 'Center Marler', distance: 69.8, elevation: 2408 },
        { name: '<a href="https://ridewithgps.com/trips/7324895">Center Road</a>', sortKey: 'Center Road', distance: 58.2, elevation: 1974 },
        { name: '<a href="https://ridewithgps.com/routes/3789846">Chicken Shack</a>', sortKey: 'Chicken Shack', distance: 33.5, elevation: 1353 },
        { name: '<a href="https://ridewithgps.com/routes/374991">Circumnavigate Pilot</a>', sortKey: 'Circumnavigate Pilot', distance: 56.2, elevation: 2482 },
        { name: '<a href="https://ridewithgps.com/routes/3789851">Cock A Doodle Do C</a>', sortKey: 'Cock A Doodle Do C', distance: 36.1, elevation: 1569 },
        { name: '<a href="https://ridewithgps.com/routes/36327351">County Line</a>', sortKey: 'County Line', distance: 58.4, elevation: 2405 },
        { name: '<a href="https://ridewithgps.com/trips/7294261">Deep Creek</a>', sortKey: 'Deep Creek', distance: 52.3, elevation: 1865 },
        { name: '<a href="https://ridewithgps.com/routes/384664">Devotion</a>', sortKey: 'Devotion', distance: 88.2, elevation: 3774 },
        { name: '<a href="https://ridewithgps.com/routes/28569494">Dinkinsford</a>', sortKey: 'Dinkinsford', distance: 47.6, elevation: 1920 },
        { name: '<a href="https://ridewithgps.com/trips/7307500">Dinkinsville</a>', sortKey: 'Dinkinsville', distance: 55.9, elevation: 2182 },
        { name: '<a href="https://ridewithgps.com/routes/29109226">Dis Union</a>', sortKey: 'Dis Union', distance: 42.3, elevation: 1422 },
        { name: '<a href="https://ridewithgps.com/routes/44099244">Disco Turkey Foot</a>', sortKey: 'Disco Turkey Foot', distance: 57.6, elevation: 2274 },
        { name: '<a href="https://ridewithgps.com/routes/44765899">Doable On 5 Hours Sleep</a>', sortKey: 'Doable On 5 Hours Sleep', distance: 46.7, elevation: 2620 },
        { name: '<a href="https://ridewithgps.com/routes/386172">Dobson</a>', sortKey: 'Dobson', distance: 69.5, elevation: 2531 },
        { name: '<a href="https://ridewithgps.com/routes/386849">Double Hump</a>', sortKey: 'Double Hump', distance: 71.4, elevation: 5140 },
        { name: '<a href="https://ridewithgps.com/routes/3834328">Dull Rd Training Loop via Williams</a>', sortKey: 'Dull Rd Training Loop via Williams', distance: 10, elevation: 439 },
        { name: '<a href="https://ridewithgps.com/routes/387721">Eagle Mills</a>', sortKey: 'Eagle Mills', distance: 63.3, elevation: 2356 },
        { name: '<a href="https://ridewithgps.com/routes/45244472">East Bound and Donnaha</a>', sortKey: 'East Bound and Donnaha', distance: 45.1, elevation: 2660 },
        { name: '<a href="https://ridewithgps.com/routes/395128">El Oasis</a>', sortKey: 'El Oasis', distance: 48, elevation: 1606 },
        { name: '<a href="https://ridewithgps.com/routes/3630144">Eva Cranfill B</a>', sortKey: 'Eva Cranfill B', distance: 35.2, elevation: 1363 },
        { name: '<a href="https://ridewithgps.com/routes/3834344">Eva Cranville C</a>', sortKey: 'Eva Cranville C', distance: 32.6, elevation: 1186 },
        { name: '<a href="https://ridewithgps.com/routes/399543">Eva Pineville</a>', sortKey: 'Eva Pineville', distance: 36.7, elevation: 1257 },
        { name: '<a href="https://ridewithgps.com/routes/3834372">Even Longer Bethania-Tobaccoville C</a>', sortKey: 'Even Longer Bethania-Tobaccoville C', distance: 32.6, elevation: 1552 },
        { name: '<a href="https://ridewithgps.com/routes/4158955">Farmington Out and Back B</a>', sortKey: 'Farmington Out and Back B', distance: 26.7, elevation: 1299 },
        { name: '<a href="https://ridewithgps.com/routes/3455601">Farmington Tour</a>', sortKey: 'Farmington Tour', distance: 77, elevation: 3615 },
        { name: '<a href="https://ridewithgps.com/trips/7376881">Flat Louise</a>', sortKey: 'Flat Louise', distance: 54.8, elevation: 1457 },
        { name: '<a href="https://ridewithgps.com/routes/45505999">Flyers Cornish Hen Stew</a>', sortKey: 'Flyers Cornish Hen Stew', distance: 42.2, elevation: 1779 },
        { name: '<a href="https://ridewithgps.com/routes/3712697">Flyer’s Chicken Stew</a>', sortKey: 'Flyer’s Chicken Stew', distance: 41.7, elevation: 1775 },
        { name: '<a href="https://ridewithgps.com/routes/38251054">Geezer Chick</a>', sortKey: 'Geezer Chick', distance: 60, elevation: 2901 },
        { name: '<a href="https://ridewithgps.com/routes/1988127">Groce Booger</a>', sortKey: 'Groce Booger', distance: 45, elevation: 1714 },
        { name: '<a href="https://ridewithgps.com/routes/406567">Groundhog Valley</a>', sortKey: 'Groundhog Valley', distance: 77.3, elevation: 2493 },
        { name: '<a href="https://ridewithgps.com/routes/3834389">Group Sex C</a>', sortKey: 'Group Sex C', distance: 39.7, elevation: 1290 },
        { name: '<a href="https://ridewithgps.com/routes/934736">Halsebeck Goff Up</a>', sortKey: 'Halsebeck Goff Up', distance: 48.7, elevation: 2085 },
        { name: '<a href="https://ridewithgps.com/trips/7376892">Hanging Rock</a>', sortKey: 'Hanging Rock', distance: 57.9, elevation: 3782 },
        { name: '<a href="https://ridewithgps.com/routes/6906840">Hauser-Pineville</a>', sortKey: 'Hauser-Pineville', distance: 27.4, elevation: 1312 },
        { name: '<a href="https://ridewithgps.com/routes/3834413">Holy Cow C</a>', sortKey: 'Holy Cow C', distance: 34, elevation: 1709 },
        { name: '<a href="https://ridewithgps.com/routes/44765826">Hornee John Revised</a>', sortKey: 'Hornee John Revised', distance: 44.7, elevation: 2720 },
        { name: '<a href="https://ridewithgps.com/trips/8260284">Houstonville</a>', sortKey: 'Houstonville', distance: 80.3, elevation: 3129 },
        { name: '<a href="https://ridewithgps.com/trips/7260755">Howell Road</a>', sortKey: 'Howell Road', distance: 27.5, elevation: 1210 },
        { name: '<a href="https://ridewithgps.com/routes/34901823">Howell U Eaton - Angell?</a>', sortKey: 'Howell U Eaton - Angell?', distance: 42.5, elevation: 1933, },
        { name: '<a href="https://ridewithgps.com/routes/34901793">Howell U Eaton Puddin?</a>', sortKey: 'Howell U Eaton Puddin?', distance: 38.8, elevation: 1874 },
        { name: '<a href="https://ridewithgps.com/routes/1205799">In Harmony</a>', sortKey: 'In Harmony', distance: 63.5, elevation: 2561 },
        { name: '<a href="https://ridewithgps.com/routes/9227413">Indian Heaps (reverse)</a>', sortKey: 'Indian Heaps (reverse)', distance: 31.5, elevation: 1208 },
        { name: '<a href="https://ridewithgps.com/routes/3609993">Joe\'s A Woussi...ckett</a > ', sortKey: 'Joe\'s A Woussi...ckett', distance: 69.4, elevation: 3338 },
        { name: '<a href="https://ridewithgps.com/routes/3834425">K.O.L.D.</a>', sortKey: 'K.O.L.D.', distance: 19.3, elevation: 1221 },
        { name: '<a href="https://ridewithgps.com/routes/3839206">LSD (long Slow Death) C</a>', sortKey: 'LSD (long Slow Death) C', distance: 37.7, elevation: 1861 },
        { name: '<a href="https://ridewithgps.com/routes/4159993">LYRMC (46)</a>', sortKey: 'LYRMC (46)', distance: 45.9, elevation: 1752 },
        { name: '<a href="https://ridewithgps.com/routes/6920774">Lashley Dull Road Loop</a>', sortKey: 'Lashley Dull Road Loop', distance: 12.9, elevation: 651 },
        { name: '<a href="https://ridewithgps.com/routes/6863508">Lashley Figure 8</a>', sortKey: 'Lashley Figure 8', distance: 19.7, elevation: 1039 },
        { name: '<a href="https://ridewithgps.com/routes/4383181">Lassiter Lake Hauser Loop</a>', sortKey: 'Lassiter Lake Hauser Loop', distance: 19.4, elevation: 937 },
        { name: '<a href="https://ridewithgps.com/routes/4159092">Lauren Rauck Rt C (reverse)</a>', sortKey: 'Lauren Rauck Rt C (reverse)', distance: 27.6, elevation: 1253 },
        { name: '<a href="https://ridewithgps.com/routes/3834536">Lauren Rauck Rt C</a>', sortKey: 'Lauren Rauck Rt C', distance: 27.6, elevation: 1253 },
        { name: '<a href="https://ridewithgps.com/routes/38616526">Leg Poison</a>', sortKey: 'Leg Poison', distance: 51.5, elevation: 2665 },
        { name: '<a href="https://ridewithgps.com/routes/3834545">Lewisville Loop C</a>', sortKey: 'Lewisville Loop C', distance: 26.1, elevation: 1248 },
        { name: '<a href="https://ridewithgps.com/routes/45239698">Lewisville New Years DAy 100K</a>', sortKey: 'Lewisville New Years DAy 100K', distance: 61.6, elevation: 3023 },
        { name: '<a href="https://ridewithgps.com/trips/7325007">Lewisville Yadkin River Metric</a>', sortKey: 'Lewisville Yadkin River Metric', distance: 69.5, elevation: 3090 },
        { name: '<a href="https://ridewithgps.com/routes/3840313">Liberty Church B</a>', sortKey: 'Liberty Church B', distance: 43.5, elevation: 1739 },
        { name: '<a href="https://ridewithgps.com/routes/3834547">Liberty Church C</a>', sortKey: 'Liberty Church C', distance: 40.2, elevation: 1503 },
        { name: '<a href="https://ridewithgps.com/routes/419379">Liberty Lake</a>', sortKey: 'Liberty Lake', distance: 47.9, elevation: 1872 },
        { name: '<a href="https://ridewithgps.com/routes/25222204">Limerock XL</a>', sortKey: 'Limerock XL', distance: 50.2, elevation: 1988 },
        { name: '<a href="https://ridewithgps.com/routes/2020527">Liquid Chicken</a>', sortKey: 'Liquid Chicken', distance: 63.3, elevation: 2450 },
        { name: '<a href="https://ridewithgps.com/routes/3834955">Long Bethania-Tobaccoville C</a>', sortKey: 'Long Bethania-Tobaccoville C', distance: 32.8, elevation: 1520 },
        { name: '<a href="https://ridewithgps.com/routes/3840306">Long Bowman B</a>', sortKey: 'Long Bowman B', distance: 38.3, elevation: 1567 },
        { name: '<a href="https://ridewithgps.com/routes/3834968">Long Bowman C</a>', sortKey: 'Long Bowman C', distance: 35.8, elevation: 1326 },
        { name: '<a href="https://ridewithgps.com/routes/6745007">Long Eva Cranfill</a>', sortKey: 'Long Eva Cranfill', distance: 31.7, elevation: 1228 },
        { name: '<a href="https://ridewithgps.com/routes/3839193">Long Moccasin Gap C</a>', sortKey: 'Long Moccasin Gap C', distance: 36.3, elevation: 1642 },
        { name: '<a href="https://ridewithgps.com/routes/3839173">Longer Geezer Chick C</a>', sortKey: 'Longer Geezer Chick C', distance: 37.1, elevation: 1624 },
        { name: '<a href="https://ridewithgps.com/routes/419672">Lost Loop</a>', sortKey: 'Lost Loop', distance: 47.5, elevation: 1462 },
        { name: '<a href="https://ridewithgps.com/trips/7389702">Lost Loop</a>', sortKey: 'Lost Loop', distance: 58.4, elevation: 1758 },
        { name: '<a href="https://ridewithgps.com/trips/7530514">Lost Louise</a>', sortKey: 'Lost Louise', distance: 53.3, elevation: 1773 },
        { name: '<a href="https://ridewithgps.com/routes/1406595">Lost Nellie</a>', sortKey: 'Lost Nellie', distance: 58.9, elevation: 2169 },
        { name: '<a href="https://ridewithgps.com/trips/8518173">Lost Thunderbolt</a>', sortKey: 'Lost Thunderbolt', distance: 60.8, elevation: 2407 },
        { name: '<a href="https://ridewithgps.com/routes/4159754">Moccasin Gap B</a>', sortKey: 'Moccasin Gap B', distance: 40.1, elevation: 1907 },
        { name: '<a href="https://ridewithgps.com/routes/4159758">Moccasin Gap C</a>', sortKey: 'Moccasin Gap C', distance: 37.6, elevation: 1675 },
        { name: '<a href="https://ridewithgps.com/trips/8260240">Mountain View</a>', sortKey: 'Mountain View', distance: 51.9, elevation: 2557 },
        { name: '<a href="https://ridewithgps.com/trips/8518171">Off Center</a>', sortKey: 'Off Center', distance: 68.1, elevation: 2856 },
        { name: '<a href="https://ridewithgps.com/trips/7376855">Old Amish Rah</a>', sortKey: 'Old Amish Rah', distance: 81.3, elevation: 2729 },
        { name: '<a href="https://ridewithgps.com/routes/3839213">Out and Back (School) C</a>', sortKey: 'Out and Back (School) C', distance: 24.8, elevation: 966 },
        { name: '<a href="https://ridewithgps.com/routes/4159449">Out and Back</a>', sortKey: 'Out and Back', distance: 29.3, elevation: 1060 },
        { name: '<a href="https://ridewithgps.com/routes/4159444">Out and back B</a>', sortKey: 'Out and back B', distance: 31.8, elevation: 1278 },
        { name: '<a href="https://ridewithgps.com/routes/4235196">Pfafftown Pyrenees</a>', sortKey: 'Pfafftown Pyrenees', distance: 24.7, elevation: 1331 },
        { name: '<a href="https://ridewithgps.com/routes/430358">Pilot Mountain</a>', sortKey: 'Pilot Mountain', distance: 48.9, elevation: 3170 },
        { name: '<a href="https://ridewithgps.com/routes/41419528">Pine Bees</a>', sortKey: 'Pine Bees', distance: 42.2, elevation: 1661 },
        { name: '<a href="https://ridewithgps.com/routes/6906910">Pineville-Wyo</a>', sortKey: 'Pineville-Wyo', distance: 28.1, elevation: 1180 },
        { name: '<a href="https://ridewithgps.com/routes/3840177">Powerline B</a>', sortKey: 'Powerline B', distance: 36.4, elevation: 1473 },
        { name: '<a href="https://ridewithgps.com/routes/3839217">Powerline C</a>', sortKey: 'Powerline C', distance: 32.6, elevation: 1193 },
        { name: '<a href="https://ridewithgps.com/routes/434015">Priddy\'s Store</a > ', sortKey: 'Priddy\'s Store', distance: 69.6, elevation: 3670 },
        { name: '<a href="https://ridewithgps.com/routes/4076252">Pudding Ridge (reverse) B</a>', sortKey: 'Pudding Ridge (reverse) B', distance: 39, elevation: 1807 },
        { name: '<a href="https://ridewithgps.com/routes/3840297">Pudding Ridge B</a>', sortKey: 'Pudding Ridge B', distance: 41.1, elevation: 1883 },
        { name: '<a href="https://ridewithgps.com/routes/4159166">Pudding Ridge C (reverse)</a>', sortKey: 'Pudding Ridge C (reverse)', distance: 36.6, elevation: 1583 },
        { name: '<a href="https://ridewithgps.com/routes/8668104">Pudding Ridge C – Pineville variation</a>', sortKey: 'Pudding Ridge C – Pineville variation', distance: 38.8, elevation: 1602 },
        { name: '<a href="https://ridewithgps.com/routes/3839223">Pudding Ridge C</a>', sortKey: 'Pudding Ridge C', distance: 36.6, elevation: 1587 },
        { name: '<a href="https://ridewithgps.com/routes/4160029">Pudding Ridgeless A</a>', sortKey: 'Pudding Ridgeless A', distance: 35.5, elevation: 1422 },
        { name: '<a href="https://ridewithgps.com/routes/3839231">Pudding Ridgeless</a>', sortKey: 'Pudding Ridgeless', distance: 35.1, elevation: 1512 },
        { name: '<a href="https://ridewithgps.com/routes/4430451">Rauck Rockett C (reverse)</a>', sortKey: 'Rauck Rockett C (reverse)', distance: 36.8, elevation: 1704 },
        { name: '<a href="https://ridewithgps.com/routes/3839299">Rauck Rockett C</a>', sortKey: 'Rauck Rockett C', distance: 36.8, elevation: 1703 },
        { name: '<a href="https://ridewithgps.com/routes/3839319">Really K.O.L.D.C</a>', sortKey: 'Really K.O.L.D.C', distance: 35, elevation: 2342 },
        { name: '<a href="https://ridewithgps.com/routes/4983504">Reverse Rockett Man C</a>', sortKey: 'Reverse Rockett Man C', distance: 37.5, elevation: 1404 },
        { name: '<a href="https://ridewithgps.com/routes/3630145">Reverse Rockford W/Out Via Williams</a>', sortKey: 'Reverse Rockford W/Out Via Williams', distance: 39.1, elevation: 1433 },
        { name: '<a href="https://ridewithgps.com/trips/42014083">Ride 4 Robbie</a>', sortKey: 'Ride 4 Robbie', distance: 67.9, elevation: 2982 },
        { name: '<a href="https://ridewithgps.com/routes/16917047">Ride for Love Metric</a>', sortKey: 'Ride for Love Metric', distance: 64.4, elevation: 3066 },
        { name: '<a href="https://ridewithgps.com/routes/437664">Rock Marlerville</a>', sortKey: 'Rock Marlerville', distance: 74.2, elevation: 2614 },
        { name: '<a href="https://ridewithgps.com/routes/3839386">Rockett Man C</a>', sortKey: 'Rockett Man C', distance: 37.5, elevation: 1404 },
        { name: '<a href="https://ridewithgps.com/routes/3840265">Rockford Without Rockford (reverse) B</a>', sortKey: 'Rockford Without Rockford (reverse) B', distance: 39.2, elevation: 1520 },
        { name: '<a href="https://ridewithgps.com/routes/3839340">Rockford Without Rockford (reverse) C</a>', sortKey: 'Rockford Without Rockford (reverse) C', distance: 36.6, elevation: 1286 },
        { name: '<a href="https://ridewithgps.com/trips/8117756">Rockford w/o Rockford</a>', sortKey: 'Rockford w/o Rockford', distance: 48.4, elevation: 1875 },
        { name: '<a href="https://ridewithgps.com/trips/7294237">Rockford</a>', sortKey: 'Rockford', distance: 66.4, elevation: 2854 },
        { name: '<a href="https://ridewithgps.com/trips/8260112">Sauratown Mountain</a>', sortKey: 'Sauratown Mountain', distance: 72.1, elevation: 3780 },
        { name: '<a href="https://ridewithgps.com/routes/22520444">Shacktown Bottom</a>', sortKey: 'Shacktown Bottom', distance: 42.7, elevation: 1580 },
        { name: '<a href="https://ridewithgps.com/routes/22520263">Shacktown Speer</a>', sortKey: 'Shacktown Speer', distance: 45.2, elevation: 1715 },
        { name: '<a href="https://ridewithgps.com/routes/3839405">Short Bethania-Tobaccoville C</a>', sortKey: 'Short Bethania-Tobaccoville C', distance: 30.4, elevation: 1429 },
        { name: '<a href="https://ridewithgps.com/routes/3840241">Short Bowman B</a>', sortKey: 'Short Bowman B', distance: 35.8, elevation: 1447 },
        { name: '<a href="https://ridewithgps.com/routes/3839408">Short Bowman C</a>', sortKey: 'Short Bowman C', distance: 33.2, elevation: 1230 },
        { name: '<a href="https://ridewithgps.com/routes/443976">Soggy Bottom</a>', sortKey: 'Soggy Bottom', distance: 58.6, elevation: 2185 },
        { name: '<a href="https://ridewithgps.com/routes/3840299">Solid Cock-A-Doodle B</a>', sortKey: 'Solid Cock-A-Doodle B', distance: 38.8, elevation: 1757 },
        { name: '<a href="https://ridewithgps.com/routes/3839419">Solid Cock-A-Doodle C</a>', sortKey: 'Solid Cock-A-Doodle C', distance: 36.2, elevation: 1520 },
        { name: '<a href="https://ridewithgps.com/routes/3839428">Some Old Farts’ Grandchildren C</a>', sortKey: 'Some Old Farts’ Grandchildren C', distance: 31.1, elevation: 1437 },
        { name: '<a href="https://ridewithgps.com/routes/7037818">Sooner Sufferfest from Lewisville</a>', sortKey: 'Sooner Sufferfest from Lewisville', distance: 119.3, elevation: 6056 },
        { name: '<a href="https://ridewithgps.com/routes/3630138">Soulman B</a>', sortKey: 'Soulman B', distance: 33.9, elevation: 1407 },
        { name: '<a href="https://ridewithgps.com/routes/3839440">Soulman C</a>', sortKey: 'Soulman C', distance: 31.4, elevation: 1236 },
        { name: '<a href="https://ridewithgps.com/routes/563436">Stuckey Bucky</a>', sortKey: 'Stuckey Bucky', distance: 60.7, elevation: 2076 },
        { name: '<a href="https://ridewithgps.com/trips/7324916">Stuckeyville</a>', sortKey: 'Stuckeyville', distance: 76.1, elevation: 2495 },
        { name: '<a href="https://ridewithgps.com/routes/42852904">Suffer Less Saturday</a>', sortKey: 'Suffer Less Saturday', distance: 50.1, elevation: 2202 },
        { name: '<a href="https://ridewithgps.com/routes/3839466">Summer Solstice 1/2 Century II</a>', sortKey: 'Summer Solstice 1/2 Century II', distance: 51.8, elevation: 1946 },
        { name: '<a href="https://ridewithgps.com/routes/4160014">Summer Solstice 1/2 Century</a>', sortKey: 'Summer Solstice 1/2 Century', distance: 50.4, elevation: 1790 },
        { name: '<a href="https://ridewithgps.com/routes/4160005">T-Ville</a>', sortKey: 'T-Ville', distance: 35.4, elevation: 1527 },
        { name: '<a href="https://ridewithgps.com/routes/3839493">Tandem Hell (reverse)</a>', sortKey: 'Tandem Hell (reverse)', distance: 33.9, elevation: 1437 },
        { name: '<a href="https://ridewithgps.com/routes/31822795">Tandem Hell</a>', sortKey: 'Tandem Hell', distance: 34, elevation: 1438 },
        { name: '<a href="https://ridewithgps.com/routes/954396">Tandem Hell</a>', sortKey: 'Tandem Hell', distance: 34.1, elevation: 1343 },
        { name: '<a href="https://ridewithgps.com/routes/447605">The Raccoon Trail</a>', sortKey: 'The Raccoon Trail', distance: 45.8, elevation: 1540 },
        { name: '<a href="https://ridewithgps.com/routes/4353900">Tour Of Winston Salem</a>', sortKey: 'Tour Of Winston Salem', distance: 73.4, elevation: 3674 },
        { name: '<a href="https://ridewithgps.com/routes/11786794">Tour de Llama</a>', sortKey: 'Tour de Llama', distance: 60.1, elevation: 2806 },
        { name: '<a href="https://ridewithgps.com/routes/23487658">Two Bump Brushy Century</a>', sortKey: 'Two Bump Brushy Century', distance: 106.7, elevation: 5922 },
        { name: '<a href="https://ridewithgps.com/routes/4160011">Union Cross/East Bend</a>', sortKey: 'Union Cross/East Bend', distance: 42.1, elevation: 1589 },
        { name: '<a href="https://ridewithgps.com/routes/455689">Up Edwards</a>', sortKey: 'Up Edwards', distance: 48.3, elevation: 1955 },
        { name: '<a href="https://ridewithgps.com/routes/3839507">Vienna Tobaccoville C</a>', sortKey: 'Vienna Tobaccoville C', distance: 29.9, elevation: 1303 },
        { name: '<a href="https://ridewithgps.com/trips/7324930">WSCC Fondo</a>', sortKey: 'WSCC Fondo', distance: 51, elevation: 2337 },
        { name: '<a href="https://ridewithgps.com/routes/3706763">Westbend Chicken Stew</a>', sortKey: 'Westbend Chicken Stew', distance: 39.7, elevation: 1805 },
        { name: '<a href="https://ridewithgps.com/routes/393034">White Plains</a>', sortKey: 'White Plains', distance: 71.7, elevation: 3024 },
        { name: '<a href="https://ridewithgps.com/routes/3706763">Williams Road Loop</a>', sortKey: 'Williams Road Loop', distance: 13.6, elevation: 584 },
        { name: '<a href="https://ridewithgps.com/routes/3839520">Williams Road Loop</a>', sortKey: 'Williams Road Loop', distance: 13.6, elevation: 584 },
        { name: '<a href="https://ridewithgps.com/routes/35111531">Windows Down in My Chevy</a>', sortKey: 'Windows Down in My Chevy', distance: 58.6, elevation: 2323 },
        { name: '<a href="https://ridewithgps.com/routes/4159433">Wyo B (reverse)</a>', sortKey: 'Wyo B (reverse)', distance: 28, elevation: 1300 },
        { name: '<a href="https://ridewithgps.com/routes/3840145">Wyo B</a>', sortKey: 'Wyo B', distance: 28.1, elevation: 1282 },
        { name: '<a href="https://ridewithgps.com/routes/4159015">Wyo C (reverse)</a>', sortKey: 'Wyo C (reverse)', distance: 25.5, elevation: 1033 },
        { name: '<a href="https://ridewithgps.com/routes/3839527">Wyo C</a>', sortKey: 'Wyo C', distance: 25.6, elevation: 1039 },
        { name: '<a href="https://ridewithgps.com/trips/8518182">Yoo Hoo 100 (Devotion - Clemmons start)', sortKey: 'Yoo Hoo 100 (Devotion - Clemmons start)', distance: 97, elevation: 4549 },
    ];
}
