export const tueThuRideData = async (setError, setVariant, user, mode) => {
    try {
        const data = { user: user, mode: mode };
        const response = await fetch(process.env.REACT_APP_EXPRESS_SERVER + '/getTueThuRides', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        const res = await response.json();
        if (response.status === 200) {
            return(res);
        } else if (response.status === 400) {
            setVariant('danger');
            setError(res.error);
            return [];
        }
    } catch (err) {
        setVariant('danger');
        if (err instanceof TypeError && err.message.includes('Failed to fetch')) {
            setError('Network error: Connection refused')
            console.error('Network error: Connection refused', err);
        } else {
            setError('An unknown error occurred.')
            console.error('An unknown error occurred:', err);
        }
        return [];
    }
}
