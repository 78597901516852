import React from 'react';
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-dark fixed-bottom">
      <div className="footer-container">
          <a className="white-links" href="https://www.facebook.com/piedmontflyers/?ref=embed_page">
            Follow us on Facebook
          </a>
          <NavLink className="white-links" style={{ marginLeft: '20px' }} to="/BikeShops">Bike Shops</NavLink>
          <NavLink className="white-links" style={{ marginLeft: '20px' }} to="/ByLaws">By-Laws</NavLink>
      </div>
    </footer>
  );
};

export default Footer;
