import 'bootstrap-icons/font/bootstrap-icons.css';
import { ErrorBoundary } from "react-error-boundary"
import './App.css';
import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Header from './Header'
import Footer from './Footer'
import Home from './Home'
import TueThuRides from './TueThuRides'
import Events from './Events'
import Library from './Library'
import MyAccount from './MyAccount'
import PayDues from './payments/PayDues'
import Login from './auth/Login'
import Logout from './auth/Logout'
import Register from './auth/Register'
import PrivateRoutes from './auth/PrivateRoutes'
import AuthProvider from "./auth/AuthProvider";
import SelectedTier from "./payments/SelectedTier";
import CreatePassword from './auth/CreatePassword'
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

// Lazy non-important stuff
const Privacy = React.lazy(() => import('./Privacy'));
const CharityRide = React.lazy(() => import('./CharityRide'));
const BikeShops = React.lazy(() => import('./BikeShops'));
const ByLaws = React.lazy(() => import('./ByLaws'));

// Lazy load admin stuff
const EditTueThuRides = React.lazy(() => import('./admin/EditTueThuRides'));
const ImportCsvFile = React.lazy(() => import('./admin/ImportCsvFile'));
const Payments = React.lazy(() => import('./admin/Payments'));
const MemberList = React.lazy(() => import('./admin/MemberList'));
const Charts = React.lazy(() => import('./admin/Charts'));
const EditDuesTiers = React.lazy(() => import('./admin/EditDuesTiers'));

function App() {
  /*
   * When a user navigates to restricted components, the
   * PrivateRoute component checks for authentication using
   * the AuthProvider. If the user is authenticated, it renders
   * the restricted components; otherwise, it redirects to
   * the /login route, ensuring protected access to restricted
   * content.
   */

  /*
   * Log error and additional error information if it gets
   * propagated to the ErrorBoundary in the App Component.
   */
  const errorHandler = (error, errorInfo) => {
    console.log("logging", error, errorInfo)
  }

  return (
    <PayPalScriptProvider
      options={{
        "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
        "disable-funding": "paylater",
        currency: "USD",
        intent: "capture"
      }}
    >
      <div className="app">
        <ErrorBoundary onError={errorHandler} fallback={<strong style={{ margin: 50 }}>Oh no! Something went terribly wrong. Refresh the page to see if we can fix this.</strong>}>
          <AuthProvider>
            <Header />
            <Container className="mainContent">
              <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/Home" element={<Home />} />
                  <Route path="/CharityRide" element={<CharityRide />} />
                  <Route path="/BikeShops" element={<BikeShops />} />
                  <Route path="/ByLaws" element={<ByLaws />} />
                  <Route path="/Login" element={<Login />} />
                  <Route path="/Logout" element={<Logout />} />
                  <Route path="/Register" element={<Register />} />
                  <Route path="/Library" element={<Library />} />
                  <Route path="/PayDues" element={<PayDues />} />
                  <Route path="/SelectedTier" element={<SelectedTier />} />
                  <Route path="/Privacy" element={<Privacy />} />
                  <Route path="/CreatePassword/:param1/:param2/:param3" element={<CreatePassword />} />
                  <Route element={<PrivateRoutes />}>
                    <Route path="/MyAccount" element={<MyAccount />} />
                    <Route path="/TueThuRides" element={<TueThuRides />} />
                    <Route path="/Events" element={<Events />} />
                    <Route path="/EditTueThuRides" element={<EditTueThuRides />} />
                    <Route path="/ImportCsvFile" element={<ImportCsvFile />} />
                    <Route path="/Payments" element={<Payments />} />
                    <Route path="/MemberList" element={<MemberList />} />
                    <Route path="/Charts" element={<Charts />} />
                    <Route path="/EditDuesTiers" element={<EditDuesTiers />} />
                  </Route>
                </Routes>
              </Suspense>
              <Footer />
            </Container>
          </AuthProvider>
        </ErrorBoundary>
      </div>
    </PayPalScriptProvider>
  );
}

export default App;