// Events calendar
import React from 'react';
import { useAuth } from './auth/AuthProvider';
import RenewDuesMsg from './RenewDuesMsg';


const Events = () => {
  const auth = useAuth();
  return (
    <>
      {
        auth.user.isDuesCurrent ? (
          <iframe className='google-calendar' title='PFBC Events Calendar'
            src="https://calendar.google.com/calendar/embed?src=19ef3e825577141dadcabccd938a33136d09c3e3ea14ce485b53ad25f2ef5b4b%40group.calendar.google.com&ctz=America%2FNew_York"
            frameBorder="0" style={{ border: 0 }} scrolling="no" height="600" width="100%" sandbox="allow-scripts allow-same-origin allow-popups">
          </iframe>
        ) : (
          <RenewDuesMsg />
        )
      }
    </>
  );
};

export default Events;
