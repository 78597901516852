export const getData = async (setError, setVariant, user, action) => {

    try {
        const response = await fetch(`${process.env.REACT_APP_EXPRESS_SERVER}/${action}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(user)
        });

        const res = await response.json();
        if (response.status === 200) {
            return res;
        }
        if (response.status === 400) {
            setVariant('danger');
            setError(res.error);
        }
    } catch (err) {
        setVariant('danger');
        if (err instanceof TypeError && err.message.includes("Failed to fetch")) {
            console.error("Network error: Connection refused", err);
            setError("Unable to connect to the server. Please try again later.");
        } else {
            console.error("An unknown error occurred:", err);
            setError("An unknown error occurred.");
        }
    }
};
