/*
 * This component is called when the user clicks the
 * submit button to submit a new user registration.
 */
export const verifyCaptchaToken = async (token, email, setError, setVariant, action) => {

    try {
        let response;
        const data = { 'email': email, 'token': token, 'action': action };
        if (action === 'register') {
            response = await fetch(process.env.REACT_APP_EXPRESS_SERVER + "/signup", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });
        } else { // Forgot password action
            response = await fetch(process.env.REACT_APP_EXPRESS_SERVER + "/forgotPassword", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });

        }
        const res = await response.json();
        if (response.status === 400) {
            setVariant('danger');
            setError(res.error);
        }
        // User's userId, email and token are
        // sent back with a 201 status. The
        // signup function in the flyers-backend
        // sends these values. Not doing anything
        // with the values at present.
    } catch (err) {
        setVariant('danger');
        if (err instanceof TypeError && err.message.includes("Failed to fetch")) {
            console.error("Network error: Connection refused", err);
            setError("Unable to connect to the server. Please try again later.");
        } else {
            console.error("An unknown error occurred:", err);
            setError("An unknown login error occurred.");
        }
    }
};
