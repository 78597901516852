import React, { useState, useRef, useEffect, Suspense } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Core CSS
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Theme
import { tueThuRideData } from './tueThuRideData';
import { isMobile } from './utils';
import { dateComparator, nameFilterParams, numberFilterParams } from './agGridUtils';
import { useAuth } from './auth/AuthProvider';
import RenewDuesMsg from './RenewDuesMsg';
import ErrorMsg from './ErrorMsg';

const TueThuRideText = React.lazy(() => import('./TueThuRideText'));

const TueThuRides = () => {

  const [rowData, setRowData] = useState([]);
  const [error, setError] = useState('');
  const [variant, setVariant] = useState('');
  const auth = useAuth();
  const bottom = useRef(null);
  const top = useRef(null);

  // Scroll to the top when the component is mounted.
  // Mobile firefox wants to position content
  // somewhere other than the top when initially
  // rendered. This prevents that from happening.
  useEffect(() => {
    isMobile() && window.scrollTo(0, 0);
  }, []);

  const scrollToBottom = () => {
    bottom?.current.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToTop = () => {
    top?.current.scrollIntoView({ behavior: "smooth" });
  };

  // Populate the grid
  useEffect(() => {
    tueThuRideData(setError, setVariant, auth.user, 'view')
      .then(data => {
        setRowData(data);
      })
      .catch(err => {
        setVariant('danger');
        setError('There was a problem displaying the rides.')
      });
  }, []);

  // Column definitions
  const [colDefs] = useState([
    {
      field: "date",
      filter: 'date',
      comparator: dateComparator,
      sortingOrder: ['asc', 'desc'],
      suppressMovable: true,
      width: 120,
    },
    {
      field: "route",
      cellRenderer: params => (
        <span dangerouslySetInnerHTML={{ __html: params.data.route }} />
      ),
      wrapText: true,
      autoHeight: true,
      filter: true,
      filterParams: nameFilterParams,
      sortingOrder: ['asc', 'desc'],
      suppressMovable: true,

      // Sort by the actual name of the ride instead
      // of the link. See the ag-cell-wrapper rule in
      // index.css. It removes link underlines and
      // enables text wrapping.
      valueGetter: params => params.data.sortKey,
    },
    {
      field: "distance",
      filter: 'number',
      filterParams: numberFilterParams,
      sortingOrder: ['asc', 'desc'],
      suppressMovable: true,
      width: 110
    },
    {
      field: "elevation",
      filter: 'number',
      filterParams: numberFilterParams,
      sortingOrder: ['asc', 'desc'],
      suppressMovable: true,
      width: 115
    },
    {
      headerName: "Ft/Mile",
      field: "fpm",
      filter: 'number',
      filterParams: numberFilterParams,
      sortingOrder: ['asc', 'desc'],
      suppressMovable: true,
      width: 120
    }
  ]);

  return (
    <div style={{ width: "100%" }}>
      <h2 ref={top}>PFBC Training Rides</h2>
      {error &&
        <ErrorMsg
          setError={setError}
          variant={variant}
          error={error}
        />
      }
      <div style={{ marginTop: 15, display: 'flex', justifyContent: 'space-between' }}>
        {auth.user.isDuesCurrent &&
          <a
            href="#"
            onClick={(event) => {
              event.preventDefault();
              scrollToBottom();
            }}
          >
            &nbsp;Road rules
          </a>
        }
      </div>
      <div className="ag-theme-quartz" >
        { auth.user.isDuesCurrent ? (
            <AgGridReact
              domLayout='autoHeight'
              rowData={rowData}
              columnDefs={colDefs}
            />
          ) : (
            <RenewDuesMsg />
          )
        }
      </div>
      {auth.user.isDuesCurrent &&
        <span>
          <a
            href="#"
            onClick={(event) => {
              event.preventDefault();
              scrollToTop();
            }}
          >
            &nbsp;Back to top
          </a>
          <Suspense fallback={<div>Loading...</div>}>
            <TueThuRideText bottom={bottom} />
          </Suspense>
        </span>
      }
    </div>
  );
};

export default TueThuRides;