import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { useAuth } from './AuthProvider'

/*
 * This code defines a PrivateRoute component for handling
 * authentication. It utilizes the useAuth hook from the
 * AuthProvider to access user authentication data. If the
 * user does not possess a token, indicating they are not
 * logged in, the code triggers a redirect to the /login
 * route using the <Navigate> component. Otherwise, it
 * renders the child components nested within the
 * PrivateRoute component accessed via <Outlet />, allowing
 * authenticated users to access the protected routes while
 * redirecting unauthenticated users to the login page.
 */
const PrivateRoutes = () => {
    const auth = useAuth();
    return(
        auth.user?.token ? <Outlet/> : <Navigate to="/login"/>
    )
}

export default PrivateRoutes