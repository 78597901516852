import { useState } from "react";
import { NavLink } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useAuth } from "./AuthProvider";
import Register from "./Register";
import { Alert, Row, Col, Container } from 'react-bootstrap';

const Login = () => {

  const signInSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required"),

    password: Yup.string()
      .required("Password is required")
      .min(4, "Password is too short - should be 4 characters minimum"),
  });

  const [initialValues, setInitialValues] = useState({
    email: "",
    password: "",
  });

  const [error, setError] = useState("");
  const auth = useAuth();
  const handleSubmit = (values) => {
    if (values.email !== "" && values.password !== "") {
      auth.loginAction(values, setError);
      return;
    }
    setError("Please provide a valid email and password.");
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={signInSchema}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {(formik) => {
        const { errors, touched, isValid, dirty } = formik;
        return (
          <Container>
            <Row className="justify-content-center">
              <Col md={5} className="desktop-margin-right">
                <h2 className="center-text">Sign in</h2>
                {error &&
                  <Alert variant="danger">
                    {error}
                  </Alert>
                }
                <Form className="login-form">
                  <div style={{ textAlign: 'center', marginBottom: 10, fontSize: 12 }}>
                    Sign in to access restricted content
                  </div>
                  <div className="mb-3 flyer-wrapper">
                    <label htmlFor="email1">Email</label>
                    <Field
                      type="email"
                      name="email"
                      id="email1"
                      className={errors.email && touched.email ?
                        "input-error" : null}
                    />
                    <ErrorMessage name="email" component="span" className="error" />
                  </div>
                  <div className="mb-3 flyer-wrapper">
                    <label htmlFor="password">Password</label>
                    <Field
                      type="password"
                      name="password"
                      id="password"
                      className={errors.password && touched.password ?
                        "input-error" : null}
                    />
                    <ErrorMessage
                      name="password"
                      component="span"
                      className="error"
                    />
                  </div>
                  <div>
                    <button
                      type="submit"
                      className={!(dirty && isValid) ? "disabled-btn" : ""}
                      disabled={!(dirty && isValid)}
                    >
                      Submit
                    </button>
                  </div>
                  <div className="draw-line" />
                  <div style={{ marginTop: 10, fontSize: 12 }}>
                    <NavLink to="/Register">Forgot Password</NavLink>
                  </div>
                </Form>
              </Col>
              <Col md={5}>
                <Register register={'register'} />
              </Col>
            </Row>
          </Container>
        );
      }}
    </Formik>
  );
};

export default Login;