import React from 'react';
import { NavLink } from "react-router-dom";

const RenewDuesMsg = () => {
    return (
        <p>
            Don't miss out on the fun! Your membership has
            expired, so you can't access the training ride schedule
            or events calendar. <NavLink to="/PayDues">Renew now</NavLink>
            {' '}to get back in the loop!
        </p>
    );
};

export default RenewDuesMsg;
