import { makeDate } from './utils';

/*
 * Various utilities for the ag-grid
 */
export function dateComparator(date1, date2) {

    const date1Num = date1 && makeDate(date1);
    const date2Num = date2 && makeDate(date2);
    if (date1Num == null && date2Num == null) {
        return 0;
    }
    if (date1Num == null) {
        return -1;
    }
    if (date2Num == null) {
        return -1;
    }
    return date1Num - date2Num;
};

export const nameFilterParams = {
    filterOptions: ['contains'],
    debounceMs: 300,
    maxNumConditions: 1,
    textFormatter: (r) => {
        if (r == null) return null;
        return r
            .toLowerCase();
    },
};

export const numberFilterParams = {
    filterOptions: ['greaterThanOrEqual', 'lessThanOrEqual']
};