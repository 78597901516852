/*
 * This module retrieves the user's id and token
 * form the email url sent as a result of
 * registing a new user or a user that has forgotten
 * his password. It verifies the token and if verified
 * displays a dialog to create a password.
 * 
 * If the token is not verified, the create password
 * dialog is not displayed. Instead, an error message
 * is displayed about the token being invalid.
 */
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Alert, Container } from 'react-bootstrap';
import { verifyEmail } from './verifyEmail';
import { createPasswordAction } from './createPasswordAction';

const CreatePassword = (props) => {

    const [error, setError] = useState('');
    const [passwordCreated, setPasswordCreated] = useState(false);
    const { param1, param2, param3 } = useParams();
    const userId = param1;
    const token = param2;
    const action = param3
    const [initialValues, setInitialValues] = useState({
        password1: "",
        password2: "",
    });

    useEffect(() => {
        verifyEmail(userId, token, setError);
    }, []);

    const createPasswordSchema = Yup.object().shape({
        password1: Yup.string().required("Password is required"),
        password2: Yup.string().label('confirm password').required().oneOf([Yup.ref('password1'), null], 'Passwords must match'),
    });

    const handleSubmit = async (values) => {
        await createPasswordAction(userId, values.password1, setError);
        if (error === '') {
            setPasswordCreated(true);
        }
    };

    return (
        <div>
            {error ? (
                <div>
                    <Alert variant="danger">
                        {error}
                    </Alert>
                </div>
            ) : (
                <div>
                    <h2>Create Password</h2>
                    <p>
                        {action === 'register' ? (
                            <span>
                                Create a password to complete your registration and
                                to access restricted content.
                            </span>
                        ) : (
                            'Create a new password.'
                        )}
                    </p>
                    {!error && passwordCreated &&
                        <p>
                            {action === 'register' ? (
                                <span>
                                    Congratulations on completing the first step
                                    toward unlocking access to our Tuesday and Thursday
                                    training ride schedule and events calendar. If you haven't
                                    already done so, complete the second step by
                                    heading over to the <a href="/PayDues">member page</a>
                                    {' '}to explore the membership tier that's right for you.
                                </span>
                            ) : (
                                <span>
                                    Your password was reset.  Please <a href='/Login'>sign in</a>.
                                </span>
                            )}
                        </p>
                    }
                    <div>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={createPasswordSchema}
                            onSubmit={(values) => {
                                handleSubmit(values);
                            }}
                        >
                            {(formik) => {
                                const { errors, touched, isValid, dirty } = formik;
                                return (
                                    <Container>
                                        <Form style={{ width: 310 }} className="login-form">
                                            <div className="mb-3 flyer-wrapper">
                                                <label htmlFor="password1">Password</label>
                                                <Field
                                                    type="password"
                                                    name="password1"
                                                    id="password1"
                                                    className={errors.password1 && touched.password1 ?
                                                        "input-error" : null}
                                                />
                                                <ErrorMessage name="password1" component="span" className="error" />
                                            </div>
                                            <div className="mb-3 flyer-wrapper">
                                                <label htmlFor="password2">Retype Password</label>
                                                <Field
                                                    type="password"
                                                    name="password2"
                                                    id="password2"
                                                    className={errors.password2 && touched.password2 ?
                                                        "input-error" : null}
                                                />
                                                <ErrorMessage
                                                    name="password2"
                                                    component="span"
                                                    className="error"
                                                />
                                            </div>
                                            <div>
                                                <button
                                                    type="submit"
                                                    className={!(dirty && isValid) ? "disabled-btn" : ""}
                                                    disabled={!(dirty && isValid)}
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </Form>
                                    </Container>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            )}
        </div>
    );
}
export default CreatePassword;
