/*
 * This module gets an email from the user and emails a token
 * and user id to them.
 */
import React, { useState, useRef } from 'react';
import { NavLink } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import { verifyCaptchaToken } from "./verifyCaptchaToken";
import ErrorMsg from '../ErrorMsg';

export default function Register(props) {

    /* 
     * This component is called for both registering a new user
     * and if he forgets his password and needs to reset it.
     */
    let action = 'register'
    if (typeof props.register === 'undefined') {
        action = 'forgotPassword';
    }

    const captchaRef = useRef(null);
    const [error, setError] = useState('');
    const [variant, setVariant] = useState('danger');
    const [initialValues, setInitialValues] = useState({
        email: "",
        recaptcha: ""
    });

    const registerSchema = Yup.object().shape({
        email: Yup.string().email().required("Email is required"),
        recaptcha: Yup.string().required()
    });

    const handleSubmitEvent = (values) => {
        try {
            const token = captchaRef.current.getValue();
            captchaRef.current.reset();
            /*
             * verifyCaptchaToken verifies the captcha and
             * stores the user's email and other values
             * in the users table. Password is stored in
             * the users table after the user clicks on
             * the link in his email and is redirected to
             * the create password dialog.
             */
            verifyCaptchaToken(token, values.email, setError, setVariant, action);
            if (error === '') {
                setVariant('secondary');
                setError('A link to create your password was sent to your email.')
            }
        } catch (error) {
            setVariant('danger');
            setError('An unknown error occurred in the registration process.')
            console.error('An unknown error occurred in the registration process.', error);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={registerSchema}
            onSubmit={(values, { resetForm }) => {
                handleSubmitEvent(values, resetForm);
                resetForm();
            }}
        >
            {(formik) => {
                const { errors, touched, isValid, dirty } = formik;
                return (
                    <div>
                        <h2
                            className="center-text">
                            {action === 'register' ? 'Register' : 'Create New Password'}
                        </h2>
                        {error &&
                            <ErrorMsg
                                setError={setError}
                                variant={variant}
                                error={error}
                            />
                        }
                        <Form className="login-form">
                            <div className="mb-3 flyer-wrapper">
                                <label htmlFor="email2">Email</label>
                                <Field
                                    type="email"
                                    name="email"
                                    id="email2"
                                    className={errors.email && touched.email ?
                                        "input-error" : null}
                                />
                                <ErrorMessage name="email" component="span" className="error" />
                            </div>
                            <p>
                                A link to create your password
                                will be sent to you.
                                Check your spam folder if you
                                don't see it.
                            </p>
                            <ReCAPTCHA className='g-recaptcha'
                                sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                                ref={captchaRef}
                                onChange={() => {
                                    formik.setFieldValue('recaptcha', 'checked');
                                }}
                            />
                            {errors.recaptcha
                                && touched.recaptcha && (
                                    <p>{errors.recaptcha}</p>
                                )}
                            <div style={{ marginTop: 10 }}>
                                <button
                                    type="submit"
                                    className={!(dirty && isValid) ? "disabled-btn" : ""}
                                    disabled={!(dirty && isValid)}
                                >
                                    Submit
                                </button>
                                <div className="draw-line" />
                                <div style={{ marginTop: 10, fontSize: 12 }}>
                                    <NavLink to="/Privacy">Privacy policy</NavLink>
                                </div>
                            </div>
                        </Form>
                    </div>
                )
            }}
        </Formik>
    );
}